import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import marked from "marked"

//faq accordion
// import { Accordion, Card } from "react-bootstrap"

// const clients = [
//   {
//     clientName: "American Venous Forum ",
//     positions: ["Chief Development Officer"],
//   },
//   {
//     clientName: "Axogen, Inc.",
//     positions: ["Vice President, Reimbursement"],
//   },
//   {
//     clientName: "Ambu",
//     positions: ["Marketing Manager", "Health Economist"],
//   },
//   {
//     clientName: "Intersect ENT",
//     positions: ["Director, Reimbursement"],
//   },
//   {
//     clientName: "Steris Corporation",
//     positions: ["Product Manager"],
//   },
//   {
//     clientName: "ReShape Lifesciences",
//     positions: ["Director of Reimbursement and Healthcare Economics"],
//   },
//   {
//     clientName: "W.L. Gore & Associates",
//     positions: ["Health Economics and Value Strategist", "Payer Strategist"],
//   },
//   {
//     clientName: "Novadaq (now Stryker)",
//     positions: ["Director, Health Economics & Outcomes Research"],
//   },
//   {
//     clientName: "Vascular Insights (now Merit)",
//     positions: [
//       "Market Access Manager (Northeast)",
//       "Market Access Manager (West)",
//       "Market Access Manager (Central)",
//     ],
//   },
//   {
//     clientName: "Centinel Spine",
//     positions: ["Product Manager"],
//   },
//   {
//     clientName: "Microline Surgical",
//     positions: ["Director of Marketing", "Marketing Manager"],
//   },
// ]

// const faqs = [
//   {
//     question:
//       'If we hire you for this search, who will do the work and be our point of contact? Does somebody "sell us" on the search, and then a different person does the work?',
//     answer:
//       "Justin is the primary point of contact for all searches. From the initial client engagement to the offer/acceptance, Justin is managing the process. We have a solid team and all members play a part in the search process to ensure quality and consistent service. Justin personally reviews and speaks with all candidates before they are presented to the client.",
//   },
//   {
//     question: "What about communication?",
//     answer:
//       "All clients receive a report weekly on the search progress. A sample of a search report can be presented upon request. Additionally, it is a company policy that all phone calls and emails are returned within 24 hours.",
//   },
//   {
//     question:
//       "You are a small firm; can you deliver like the bigger more nationally recognized firms?",
//     answer:
//       'The benefit of our firm is the size, we serve a handful of clients with high touch service. This means we have the smallest "hand&#8217;s off" list in the industry. We also have the agility to service our client&#8217;s unique need as it may move. We are also backed by our parent organization MRI. (www.mrinetwork.com) This offers us the ability to be standalone, nimble, adaptable AND offer services that other smaller organizations cannot.',
//   },
// ]

const SecondPage = () => (
  <Layout>
    <SEO
      title="Executive Search"
      description="JBL Sourcing is a provider of full-service recruitment, retention, and
					other consultative solutions. Our niche area integrates us at the
					highest levels of leadership."
    />
    <div
      className="page-headline"
      style={{
        backgroundImage: `url(${require("../images/handshake.jpg")})`,
      }}
    >
      <div className="container">
        <div className="section-heading text-center">
          <h6 className="font-weight-bold text-uppercase text-white flair">
            Services
          </h6>
          <h1 className="text-white">Executive Search</h1>
        </div>
      </div>
    </div>
    {/* <div className="page-content">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-9">
						<div className="mb-5">
							<h2 className="text-center p-0 mt-3 mb-2">
								MedTech and Life Sciences Precision Executive Search
							</h2>
							<div className="heading-line2 mx-auto" />
						</div>
						<p>
							JBL Sourcing is a provider of full-service recruitment, retention,
							and other consultative solutions. Our niche area integrates us at
							the highest levels of leadership. Our clients enjoy a full array
							of solutions customized to meet their unique and individual needs,
							including Retained and Contingency Search, Interim Staffing, and
							Succession Planning.
						</p>

						<p>
							We listen carefully to our clients’ corporate desired outcome. We
							identify the best course of action and work with diligence to
							provide the solution. We measure our success by solving client
							problems and putting the best person in the right position.
						</p>
						<p>
							We build strong relationships with our clients that get to the
							heart of achieving their objectives and creating pathways to
							success.
						</p>
						<p>
							Our recruiting systems enable us to evaluate and precision-match
							candidates with specific client needs. We do this while
							maintaining low interview to placement ratios.
						</p>
					</div>
				</div>
			</div>
		</div> */}
    <div className="page-content bg-light">
      <div className="container">
        <div className="mb-5">
          <h3 className="text-center p-0 mt-3 mb-2">
            Executive Search Process
          </h3>
          <div className="heading-line2 mx-auto" />
        </div>
        <div className="row justify-content-center alt-bg-color">
          <div className="col-md-6 mb-4">
            <div className="content-box h-100 text-white">
              <h6 className="text-uppercase font-weight-bold p-0 mb-2">
                Phase One
              </h6>
              <div className="heading-line2 mb-3" />
              <h4 className="pt-0 m-0">
                Position Review and Search Assessment
              </h4>
              <p>
                We begin by reviewing the organizational needs related to the
                position, strategic objectives, immediate leadership needs, and
                the scope of the services delivered by the area served. This
                information is collected by interviewing key stakeholders and
                conducting a position survey. We will work one on one with the
                hiring executive and/or board members to review the information
                and make recommendations that will help the organization find
                success in hiring.
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="content-box h-100 text-white">
              <h6 className="text-uppercase font-weight-bold p-0 mb-2">
                Phase Two
              </h6>
              <div className="heading-line2 mb-3" />
              <h4 className="pt-0 m-0">Research and Strategy</h4>
              <p>
                Not all position recruiting is the same. We customize a strategy
                that will be effective for each individual client and position.
                This primarily includes using our vast referral network, online
                databases, and extensive professional research.
              </p>
              <a href="https://www.jblsourcing.com/" rel="noopener noreferrer">
                www.jblsourcing.com
              </a>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="content-box h-100 text-white">
              <h6 className="text-uppercase font-weight-bold p-0 mb-2">
                Phase Three
              </h6>
              <div className="heading-line2 mb-3" />
              <h4 className="pt-0 m-0">Recruiting and Development</h4>
              <p>
                Once the position profile is created and the strategy is
                defined, the real work begins. JBL Sourcing will provide a broad
                reach to the right people. During this process we also work with
                our clients’ internal candidates to help them do their best in
                the interview process. Additionally, with internal candidates,
                we include professional coaching and career development
                feedback. Should your internal candidate not be selected, they
                will have additional tools to help them succeed within your
                organization.{" "}
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="content-box h-100 text-white">
              <h6 className="text-uppercase font-weight-bold p-0 mb-2">
                Phase Four
              </h6>
              <div className="heading-line2 mb-3" />
              <h4 className="pt-0 m-0">
                Candidate Profile Presentation and the Interview Process
              </h4>
              <p>
                JBL Sourcing will provide a short-list and profiles for the best
                and most qualified people interested in the position. We will
                have conducted a complete interview with each candidate focused
                on their wish list and skill sets for each of these individuals to
                provide the client with enough information to conduct a
                well-informed interview. Prior to interviewing, we will provide
                you coaching and evaluation tools to help assess the candidates
                evenly throughout the process. We will then assist the client in
                arranging interviews and prepare the selected candidates
                regarding what they can expect throughout the process.{" "}
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="content-box h-100 text-white">
              <h6 className="text-uppercase font-weight-bold p-0 mb-2">
                Phase Five
              </h6>
              <div className="heading-line2 mb-3" />
              <h4 className="pt-0 m-0">Candidate Selection</h4>
              <p>
                After the interviews have been completed, we will assist the
                client in collecting and sorting feedback. We host a conference
                call to review the information collected throughout the process,
                so you can make the most informed decision.
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="content-box h-100 text-white">
              <h6 className="text-uppercase font-weight-bold p-0 mb-2">
                Phase Six
              </h6>
              <div className="heading-line2 mb-3" />
              <h4 className="pt-0 m-0">
                Negotiating the Offer and On-Boarding
              </h4>
              <p>
                Once you have made the final selection, we will work with you to
                put your best foot forward crafting an offer. We serve as your
                liaison during the offer and acceptance process to ensure a
                smooth transition. Once the offer has been accepted, we will
                advise you regarding follow up to ensure a smooth start date and
                transition into the organization.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="page-content key-metrics">
      <div className="container">
        <div className="mb-5">
          <h3 className="text-center text-white p-0 mt-3 mb-2">Key Metrics</h3>
          <div className="heading-line2 mx-auto" />
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="content-box text-center bg-white h-100 p-3">
              <h3 className="m-0 display-5 pb-3 text-blue">100%</h3>
              <p>The fill rate of our priority searches. *</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="content-box text-center bg-white h-100 p-3">
              <h3 className="m-0 display-5 pb-3 text-blue">2</h3>
              <p>
                The average number of precision-matched candidates we present to
                our clients after we interview them.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="content-box text-center bg-white h-100 p-3">
              <h3 className="m-0 display-5 pb-3 text-blue">82 Days</h3>
              <p>The average length of time for us to complete a search. **</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="content-box text-center bg-white h-100 p-3">
              <h3 className="m-0 display-5 pb-3 text-blue">3 Years</h3>
              <p>
                The average time the candidates we place stay with our clients.
              </p>
            </div>
          </div>
        </div>
        <p className="small text-italic text-center text-white-50">
          * not including searches cancelled by the client.
          <br />
          ** from signed contract to offer presented and accepted.
        </p>
      </div>
    </div> */}
    {/* <div className="page-content bg-light">
      <div className="container">
        <div className="mb-5">
          <h3 className="text-center p-0 mt-3 mb-2">
            Clients and Recently Filled Positions
          </h3>
          <div className="heading-line2 mx-auto" />
          <div className="row mt-5">
            {clients.map(client => (
              <div className="col-md-4 col-lg-3 mb-4">
                <div className="bg-white p-3 border h-100">
                  <h5 className="m-0 pb-3">{client.clientName}</h5>
                  <ul>
                    {client.positions.map(position => (
                      <li>{position}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div> */}
    {/* <div className="page-content">
      <div className="container">
        <div className="mb-5">
          <h3 className="text-center p-0 mt-3 mb-2">
            Frequently Asked Questions
          </h3>
          <div className="heading-line2 mx-auto" />
          <div className="row justify-content-center mt-5">
            <div className="col-lg-9">
              <Accordion defaultActiveKey="0">
                {faqs.map((faq, i) => (
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as="button" variant="link" eventKey={i}>
                        {faq.question}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={i}>
                      <Card.Body>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: marked(faq.answer),
                          }}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  </Layout>
)

export default SecondPage
